import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import { ErrorResponse } from "@/services/axios/error";

import { PackageAPI } from "@/services/package";
import { IPackageDTO } from "@/models/Package";

const name = "PackageModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class PackageModule extends VuexModule {
  packageData = [] as IPackageDTO[];
  error = {} as ErrorResponse;

  get getPackageData() {
    return this.packageData;
  }

  @Mutation
  getListSuccess(data: IPackageDTO[]) {
    this.packageData = data;
  }

  @Mutation
  getListFailure(error: ErrorResponse) {
    this.error = error;
  }

  @Action
  async fetchList() {
    const servicesPackageAPI = new PackageAPI();
    const { data, error } = await servicesPackageAPI.getList();

    if (error) {
      this.getListFailure(error);
      return;
    }

    this.getListSuccess(data);
  }
}

export default getModule(PackageModule);
